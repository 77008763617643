import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { makeObservable } from 'mobx';
import { SideMenuItem, SideMenuSubItem } from '../modals/side-menu.modal';
import { SideMenuConversion } from '../conversion/side-menu.conversion';
import { CurrentUserPreferenceStore } from './current-user-preference.store';

@Injectable({
  providedIn: 'root',
})
export class SideMenuStore {
  menuItems: SideMenuItem[] = [];

  constructor(
    route: Router,
    private sideMenuConversion: SideMenuConversion,
    private currentUserPreferenceStore: CurrentUserPreferenceStore
  ) {
    makeObservable(this);
    this.loadInitialMenu();
    this.updateMenuActiveState(route.url);
  }

  loadInitialMenu() {
    this.menuItems = this.sideMenuConversion.resToList(
      this.currentUserPreferenceStore.userPermissions
    );
  }

  // Function to check if a sub-module has the specified permission within a main module
  hasPermission(
    mainModuleName: string,
    subModuleName: string,
    permissionType: keyof SideMenuSubItem['permissions']
  ): boolean {

    //@todo - quick fix for permission issue
    if(!mainModuleName || !subModuleName){
      return true;
    }

    // Find the main module from the menuItems array
    const mainModule = this.menuItems.find(
      (item) => item.module === mainModuleName
    );

    // If the main module is not found, return false
    if (!mainModule || !mainModule.children) {
      return false;
    }

    // Find the sub-module (child) within the main module
    const subModule = mainModule.children.find(
      (child) => child.module === subModuleName
    );

    // If the sub-module is not found or if it lacks the required permissions, return false
    if (!subModule || !subModule.permissions) {
      return false;
    }

    // Check the requested permission (manage, modify, or view)
    return subModule.permissions[permissionType] === true;
  }

  activateMainMenu(selectedItem: SideMenuItem): void {
    this.menuItems.forEach((item) => {
      if (item.module === selectedItem.module) {
        item.isCollapse = !item.isCollapse;
        item.isActive = true;
      } else {
        item.isActive = false;
        item.isCollapse = false;
      }
    });
  }

  activateSubMenu(selectedItem: SideMenuSubItem): void {
    this.menuItems.forEach((mainItem) => {
      mainItem.children?.forEach((subItem) => {
        if (subItem === selectedItem) {
          subItem.isActive = true;
          mainItem.isActive = true;
        } else {
          subItem.isActive = false;
        }
      });
    });
  }

  updateMenuActiveState(currentRoute: string): void {
    this.menuItems.forEach((mainItem) => {
      mainItem.children?.forEach((subItem) => {
        if (subItem.route === currentRoute) {
          subItem.isActive = true;
          mainItem.isCollapse = true;
          mainItem.isActive = true;
        } else {
          subItem.isActive = false;
        }
      });
    });
  }
}
