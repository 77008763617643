import { Injectable } from '@angular/core';
import { MainConversionInterface } from '../interfaces/conversion/main-conversion.interface';
import { FormGroup } from '@angular/forms';
import { CurrentUserPreferenceStore } from '../store/current-user-preference.store';
import { SideMenuItem, SideMenuSubItem } from '../modals/side-menu.modal';

@Injectable({
  providedIn: 'root',
})
export class SideMenuConversion
  implements MainConversionInterface<SideMenuItem, undefined, undefined>
{
  private lang: string;
  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
  ) {
    this.lang = currentUserPreferenceStore.languageKey;
  }

    resToList(response: any[]): SideMenuItem[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        module: item.identifier,
        icon: item.icon,
        label: item['title_' + this.lang],
        baseRoute: item.route,
        order: item.order,
        isCollapse: false,
        isActive: false,
        children: this.resToSubMenuList(item.sub_modules),
        
      };
    });
    return result;
  }

  formGroupToForm(formGroup: FormGroup): undefined {
    throw new Error('Method not implemented.');
  }
  resToForm(response: any) {
    throw new Error('Method not implemented.');
  }
  resToDetails(response: any): undefined {
    throw new Error('Method not implemented.');
  }


  resToSubMenuList(response: any[]): SideMenuSubItem[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id,
        label: item['title_' + this.lang],
        route: item.route,
        module: item.identifier,
        icon: item.icon,
        order: item.order,
        isActive:false,
        permissions: {
          manage: item.permissions.manage ?? false,
          modify: item.permissions.modify ?? false,
          view: item.permissions.view ?? false
        }
      };
    });
    return result;
  }
}
